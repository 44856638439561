import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../components/firebase-config";
import { getUserData, registerMember } from "../components/firebaseFunctions";
import { UserDataResponse } from "../types";
import LoadingSpinner from "../components/LoadingSpinner";
import { useTranslation } from "react-i18next"; // i18next import

// const subscriptionLabels = ["Free", "Basic", "Standard", "Premium"];

type UserContextType = {
  userDetails: UserDetails;
  setUserDetails: React.Dispatch<React.SetStateAction<UserDetails>>;
  loginOption: LoginOption;
  setLoginOption: React.Dispatch<React.SetStateAction<LoginOption>>;
};

//export UserContextType for use in other components
export type { UserContextType };
const UserContext = createContext<UserContextType | null>(null);

type LoginOption = {
  isRegisterMode: boolean;
  isTermsOfServiceAgreed: boolean;
  isPrivacyPolicyAgreed: boolean;
};

type UserDetails = {
  id: string;
  UID: string;
  // name: string;
  // phoneNumber: string;
  providerId: string;
  subscriptionType: string;
  sCredits: number;
  eCredits: number;
  userLevel: number;
  channels: string[];
};

// function formatDate(date: Date) {
//   return date.toLocaleDateString("ko-KR"); // 예: '2021. 12. 17.'
// }

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState<UserDetails>({
    id: "Guest",
    UID: "",
    // name: "",
    // phoneNumber: "",
    providerId: "",
    subscriptionType: "",
    sCredits: 0,
    eCredits: 0,
    userLevel: 9,
    channels: [],
  });
  const [loginOption, setLoginOption] = useState<LoginOption>({
    isRegisterMode: false,
    isTermsOfServiceAgreed: false,
    isPrivacyPolicyAgreed: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const UserDataResponseToUserDetails = (
    userData: UserDataResponse,
    providerId: string
  ): UserDetails => {
    const userItem: UserDetails = {
      id: userData.Email,
      UID: userData.UID,
      // name: userData.Name,
      // phoneNumber: userData.PhoneNumber,
      providerId: providerId,
      subscriptionType: userData.SubscriptionType,
      sCredits: userData.SCredits,
      eCredits: userData.ECredits,
      userLevel: userData.UserLevel,
      channels: userData.Channels,
    };
    return userItem;
  };

  const SetUserDetailsAsGuest = () => {
    const userItem: UserDetails = {
      id: "Guest",
      UID: "",
      // name: "",
      // phoneNumber: "",
      providerId: "",
      subscriptionType: "",
      sCredits: 0,
      eCredits: 0,
      userLevel: 9,
      channels: [],
    };
    setUserDetails(userItem);
  };

  const isRegisterModeRef = useRef(loginOption.isRegisterMode);
  // const userNameRef = useRef(loginOption.userName);
  // const phoneNumberRef = useRef(loginOption.phoneNumber);

  useEffect(() => {
    isRegisterModeRef.current = loginOption.isRegisterMode;
    // userNameRef.current = loginOption.userName;
    // phoneNumberRef.current = loginOption.phoneNumber;
  }, [loginOption]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.email != null) {
        const userId = user.email;
        const uid = user.uid;
        if (isRegisterModeRef.current) {
          try {
            setIsLoading(true);
            const userData = await registerMember(userId);
            const userdetail = UserDataResponseToUserDetails(
              userData,
              user.providerId
            );
            setUserDetails(userdetail);
          } catch (error: any) {
            await signOut(auth);
            SetUserDetailsAsGuest();
            alert(error.message);
          } finally {
            setIsLoading(false);
          }
        } else {
          const fetchUserData = async () => {
            try {
              setIsLoading(true);
              const userData = await getUserData(uid, userId);
              const userdetail = UserDataResponseToUserDetails(
                userData,
                user.providerId
              );
              setUserDetails(userdetail);
            } catch (error: any) {
              await signOut(auth);
              SetUserDetailsAsGuest();
              alert(error.message);
            } finally {
              setIsLoading(false);
            }
          };
          await fetchUserData();
        }
      } else {
        SetUserDetailsAsGuest();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ userDetails, setUserDetails, loginOption, setLoginOption }}
    >
      {isLoading && <LoadingSpinner message={t("user_context.waitForLogin")} />}
      {children}
    </UserContext.Provider>
  );
};
